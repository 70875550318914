@import "node_modules/bootstrap/scss/functions";

$blue: #0071bd;
$red: #dc3545;
$yellow: #ffc107;
$green: #198754;
$cyan: #0dcaf0;

$white: #fff;
$gray-100: #eef1f4;
$gray-600: #6c757d;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
);

$colors: (
    "blue": $blue,
    "red": $red,
    "yellow": $yellow,
    "green": $green,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800,
);

@import "node_modules/bootstrap/scss/variables";

$font-family-sans-serif: "Roboto", sans-serif;

$link-decoration: none;

$border-radius: 0.25rem;
$border-radius-sm: 0.125rem;
$border-radius-md: 0.5rem;
$border-radius-lg: 0.75rem;
$border-radius-xl: 2.25rem;
$border-radius-pill: 50rem;

$headings-font-weight: 700;

$blockquote-margin-y: 0;

$hr-opacity: 0.15;

$table-variants: (
    "light": $light,
);

$input-btn-focus-width: 0.2rem;
$input-btn-focus-color-opacity: 0.25;
$input-btn-focus-color: rgba($primary, $input-btn-focus-color-opacity);
$input-btn-focus-blur: 0;
$input-btn-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width
    $input-btn-focus-color;

$input-focus-border-color: tint-color($primary, 25%);
$input-focus-width: 0.2rem;
$input-focus-box-shadow: $input-btn-focus-box-shadow;

$form-select-focus-border-color: tint-color($primary, 25%);
$form-select-focus-width: 0.2rem;
$form-select-focus-box-shadow: $input-btn-focus-box-shadow;

$btn-focus-width: 0;

$btn-transition: none;

$btn-hover-bg-shade-amount: 0%;
$btn-hover-bg-tint-amount: 0%;
$btn-hover-border-shade-amount: 0%;
$btn-hover-border-tint-amount: 0%;
$btn-active-bg-shade-amount: 0%;
$btn-active-bg-tint-amount: 0%;
$btn-active-border-shade-amount: 0%;
$btn-active-border-tint-amount: 0%;

$pagination-focus-box-shadow: none;
$pagination-transition: none;

$card-cap-bg: transparent;

$tooltip-font-size: 0.8rem;
$tooltip-opacity: 0.95;

$toast-max-width: 400px;
$toast-font-size: 1rem;
$toast-background-color: $gray-900;
$toast-border-width: 0;

$alert-padding-y: 0.75rem;

$breadcrumb-font-size: 0.9rem;
$breadcrumb-padding-y: 0.75rem;
$breadcrumb-margin-bottom: 0;

$btn-close-focus-shadow: none;

$offcanvas-horizontal-width: 300px;

$navbar-toggler-transition: none;

$nav-link-transition: none;

$modal-content-border-width: 0;

$zindex-dropdown: 1030;
$zindex-sticky: 1020;

$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
    6: $spacer * 4,
);

$gutters: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
    6: $spacer * 4,
);

@import "node_modules/bootstrap/scss/utilities";

$utilities: map-merge(
    $utilities,
    (
        "border-end": (
            property: border-right,
            class: border-right,
            values: (
                null: $border-width solid $border-color,
                0: 0,
            ),
        ),
        "border-start": (
            property: border-left,
            class: border-left,
            values: (
                null: $border-width solid $border-color,
                0: 0,
            ),
        ),
        "rounded": (
            property: border-radius,
            class: rounded,
            values: (
                null: $border-radius,
                0: 0,
                1: $border-radius-sm,
                2: $border-radius-md,
                3: $border-radius-lg,
                4: $border-radius-xl,
                circle: 50%,
                pill: $border-radius-pill,
            ),
        ),
        "rounded-end": (
            property: border-top-right-radius border-bottom-right-radius,
            class: rounded-right,
            values: (
                null: $border-radius,
            ),
        ),
        "rounded-start": (
            property: border-bottom-left-radius border-top-left-radius,
            class: rounded-left,
            values: (
                null: $border-radius,
            ),
        ),
        "float": (
            responsive: true,
            property: float,
            values: (
                left: left,
                right: right,
                none: none,
            ),
        ),
        "start": (
            property: left,
            class: left,
            values: $position-values,
        ),
        "end": (
            property: right,
            class: right,
            values: $position-values,
        ),
        "margin-end": (
            responsive: true,
            property: margin-right,
            class: mr,
            values:
                map-merge(
                    $spacers,
                    (
                        auto: auto,
                    )
                ),
        ),
        "margin-start": (
            responsive: true,
            property: margin-left,
            class: ml,
            values:
                map-merge(
                    $spacers,
                    (
                        auto: auto,
                    )
                ),
        ),
        "padding-end": (
            responsive: true,
            property: padding-right,
            class: pr,
            values: $spacers,
        ),
        "padding-start": (
            responsive: true,
            property: padding-left,
            class: pl,
            values: $spacers,
        ),
        "text-align": (
            responsive: true,
            property: text-align,
            class: text,
            values: (
                left: left,
                right: right,
                center: center,
                justify: justify,
            ),
        ),
    )
);
